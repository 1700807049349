//
// quill.js
// Theme module
//

import Quill from 'quill';

const toggles = document.querySelectorAll('[data-quill]');

toggles.forEach((toggle) => {
  const elementOptions = toggle.dataset.quill ? JSON.parse(toggle.dataset.quill) : {};
  
  const {id} = toggle;
  const input = document.querySelector(`[name="${id}"]`);

  const defaultOptions = {
    modules: {
      toolbar: [
        ['bold', 'italic'],
        ['link', 'blockquote', 'code', 'image'],
        [
          {
            list: 'ordered',
          },
          {
            list: 'bullet',
          },
        ],
      ],
    },
    theme: 'snow',
  };

  const options = {
    ...defaultOptions,
    ...elementOptions,
  };

  const editor = new Quill(toggle, options);
  editor.on('text-change', function(delta, oldDelta, source) {
    input.value = editor.getText();
    console.log(input.value, "test")
  });
  
});



// Make available globally
window.Quill = Quill;
